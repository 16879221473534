<template>
  <div class="home">
    <AuthenticationLoginIndex/>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AuthenticationLoginIndex from '@/views/Authentication/Login/Index';

// Logic
export default {
  name: 'HomeIndex',
  mixins: [Common],
  components: {
    AuthenticationLoginIndex
  },
  mounted() {
    this.initialize();
  }
}
</script>
